import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { isBrowser } from "../../../../context/ApolloContext"

import {
  StickySiderBarSection,
  RowWrapper,
  StickySideBarLeft,
  FullWidthRow,
} from "../../../../components/Layout/styled"

import {
  PageTitle,
  BottomStickyBanner,
  StickyContainer,
  StickyText,
  StickyButton,
} from "../../../../components/BackPainPageLayout/styled"

import Footer from "../../../../components/Layout/Footer"
import PageContent from "../../../../components/BackPainPageLayout/PageContent"
import PageTopContent from "../../../../components/BackPainPageLayout/PageTopContent"
import SeoComp from "../../../../components/SeoComp"

const $ = isBrowser ? require("jquery") : {}

const pageClassName = "LeMalDeDosAuBureau"

const LeMalDeDosAuBureau = ({ intl, data }) => {
  const scrollContent = () => {
    $("." + pageClassName + " li").click(function() {
      $("html, body").animate(
        {
          scrollTop:
            $("#" + pageClassName + "_" + $(this).index()).offset().top - 200,
        },
        1500
      )
    })
  }

  useEffect(() => {
    scrollContent()
  }, [])

  return (
    data &&
    data.allDirectusVosMauxDeDosTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: `les-maux-de-dos/les-maux-de-dos-au-quotidien/le-mal-de-dos-au-bureau`,
            en: "",
            de: "",
            es: "",
          }}
        />
        <StickySiderBarSection direction="row" align="flex-start">
          <StickySideBarLeft>
            <FullWidthRow direction="column">
              <RowWrapper>
                <PageTitle
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusVosMauxSubPageTranslation.nodes[0]
                        .page_title,
                  }}
                />
              </RowWrapper>
              <FullWidthRow direction="column" align="flex-start">
                <RowWrapper direction="column" align="flex-start">
                  {data.allDirectusVosMauxSubPageTranslation.nodes[0]
                    .page_image &&
                    data.allDirectusVosMauxSubPageTranslation.nodes[0]
                      .introduction_text && (
                      <PageTopContent
                        image={
                          data.allDirectusVosMauxSubPageTranslation.nodes[0]
                            .page_image
                        }
                        text={
                          data.allDirectusVosMauxSubPageTranslation.nodes[0]
                            .introduction_text
                        }
                        pageID={pageClassName}
                      />
                    )}
                </RowWrapper>
                <RowWrapper direction="column" align="flex-start">
                  {data.allDirectusVosMauxSubPageTranslation.nodes[0].page_content.list.map(
                    (item, index) => {
                      return (
                        item && (
                          <PageContent
                            key={index.toString()}
                            pageID={`${pageClassName}_${index.toString()}`}
                            data={item}
                          />
                        )
                      )
                    }
                  )}
                </RowWrapper>
                <BottomStickyBanner>
                  <StickyContainer>
                    <StickyText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusVosMauxSubPageTranslation.nodes[0]
                            .sticky_banner_text,
                      }}
                    />
                    <StickyButton
                      to={
                        data.allDirectusVosMauxSubPageTranslation.nodes[0]
                          .sticky_button_link
                      }
                    >
                      {
                        data.allDirectusVosMauxSubPageTranslation.nodes[0]
                          .sticky_button_text
                      }
                    </StickyButton>
                  </StickyContainer>
                </BottomStickyBanner>
              </FullWidthRow>
            </FullWidthRow>
          </StickySideBarLeft>
        </StickySiderBarSection>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusVosMauxDeDosTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        pathologies_page_content {
          title
          read_article_text
          list {
            image
            title
            text
            link
          }
        }
        product_sidebar_content {
          title
          subtitle
          mobile_button_text
          homme_text
          femme_text
          list {
            image
            link
          }
        }
        sub_page_bottom_section_title
        sub_page_bottom_video_section {
          video_url
          button_text
          text
          list {
            title
            text
          }
        }
        sub_page_bottom_logo_section {
          list {
            image
          }
        }
        sub_page_bottom_review_section {
          list {
            image
            author
            designation
            text
          }
        }
        sub_page_product_section_title
        sub_page_lyneup_product {
          title
          subtitle
          image
          button_text
          text_list {
            image
            title
            text
          }
        }
        sub_page_lynefit_product {
          title
          subtitle
          image
          button_text
          text_list {
            image
            title
            text
          }
        }
        sub_page_product_section_bottom_text
        sub_page_pictos_section {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusVosMauxSubPageTranslation(
      filter: {
        language: { eq: $lang }
        vos_maux_sub_page: { name: { eq: "Le mal de dos au bureau" } }
      }
    ) {
      nodes {
        page_title
        page_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
            extension
          }
        }
        introduction_text
        page_content {
          list {
            title
            text
            text_list {
              title
              text
            }
          }
        }
        page_content_with_image {
          list {
            image
            title
            text
            text_list {
              title
              text
            }
          }
        }
        sticky_banner_text
        sticky_button_text
        sticky_button_link
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "le_mal_de_dos_au_bureau" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(LeMalDeDosAuBureau)
